// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pcs-articles-productive-computational-science-platform-tsx": () => import("./../../../src/pages/pcs/articles/productive-computational-science-platform.tsx" /* webpackChunkName: "component---src-pages-pcs-articles-productive-computational-science-platform-tsx" */),
  "component---src-pages-pcs-community-tsx": () => import("./../../../src/pages/pcs/community.tsx" /* webpackChunkName: "component---src-pages-pcs-community-tsx" */),
  "component---src-pages-pcs-documentation-tsx": () => import("./../../../src/pages/pcs/documentation.tsx" /* webpackChunkName: "component---src-pages-pcs-documentation-tsx" */),
  "component---src-pages-pcs-index-tsx": () => import("./../../../src/pages/pcs/index.tsx" /* webpackChunkName: "component---src-pages-pcs-index-tsx" */),
  "component---src-pages-pcs-join-pcs-tsx": () => import("./../../../src/pages/pcs/join-pcs.tsx" /* webpackChunkName: "component---src-pages-pcs-join-pcs-tsx" */),
  "component---src-pages-pcs-projects-edge-neural-network-tsx": () => import("./../../../src/pages/pcs/projects/edge-neural-network.tsx" /* webpackChunkName: "component---src-pages-pcs-projects-edge-neural-network-tsx" */),
  "component---src-pages-pcs-projects-hardware-data-structures-tsx": () => import("./../../../src/pages/pcs/projects/hardware-data-structures.tsx" /* webpackChunkName: "component---src-pages-pcs-projects-hardware-data-structures-tsx" */),
  "component---src-pages-pcs-projects-hybrid-programming-model-tsx": () => import("./../../../src/pages/pcs/projects/hybrid-programming-model.tsx" /* webpackChunkName: "component---src-pages-pcs-projects-hybrid-programming-model-tsx" */),
  "component---src-pages-pcs-projects-index-tsx": () => import("./../../../src/pages/pcs/projects/index.tsx" /* webpackChunkName: "component---src-pages-pcs-projects-index-tsx" */),
  "component---src-pages-pcs-why-pcs-tsx": () => import("./../../../src/pages/pcs/why-pcs.tsx" /* webpackChunkName: "component---src-pages-pcs-why-pcs-tsx" */)
}

